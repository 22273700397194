import React from 'react';
import classNames from 'classnames';

import CustomTableTitle from '../customTableTitle/CustomTableTitle';
import ShipmentButton from '../../button/shipmentButton/ShipmentButton';
import './ShippingTable.scss';

const ShippingTable = ({ title, shipping, editable, onClick, ...props }) => {
  const customTableClass = classNames({
    'custom-table-container shipping': true,
    large: props?.large,
    'extra-large': props?.extraLarge,
  });

  return (
    <div className={customTableClass}>
      <CustomTableTitle title={title} editable={editable} onClick={onClick} />
      <div className="product-table-body flex-box center space-between">
        <div className="flex-box space-between column">
          <span className="title head3 extrabold">${shipping?.cost}</span>
          <span className="title bold">{`(Shipping and Product fee included)`}</span>
        </div>
        {shipping?.frequency && <ShipmentButton label={shipping?.frequency} active />}
      </div>
    </div>
  );
};

export default ShippingTable;
