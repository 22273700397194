import React from 'react';
import dayjs from 'dayjs';

import './PayoutsListItem.scss';

const PayoutsListItem = ({ id, created_at, amount, cardNumber }) => {
  return (
    <div className="custom-table-container extra-large payout-item">
      <div className="custom-table-title">
        <span className="title bold">Completed</span>
        <span className="title bold">{`Payout #${id}`}</span>
      </div>
      <div className="product-table-body flex-box space-between center ">
        <div className="flex-box column">
          <span className="title">
            <span className="title bold">Date:</span>
            {` ${dayjs(created_at).format('MMMM D, YYYY h:mm a')}`}
          </span>
        </div>
        <span className="title head3 extrabold">{`$${amount.toFixed(2)}`}</span>
      </div>
    </div>
  );
};

export default PayoutsListItem;
