import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { toast } from 'react-toastify';

import ModalOverlay from '../ModalOverlay';
import MyInput from '../../../collections/ui/input/MyInput';
import MyCalendar from '../../calendar/MyCalendar';
import {
  companyLabels,
  representativeLabels,
  ownersLabels,
} from '../../../account/doctorAccount/accountInfo/labelNames';
import './EditKycRequirementsModal.scss';

const EditKycRequirementsModal = ({ setShow, requirements, setRequirements, ...props }) => {
  const [kycRequirements, setKycRequirements] = useState(requirements);
  const [isShowPopups, setIsShowPopups] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = () => {
    setRequirements(kycRequirements);
    saveKycInfo();
  };

  useEffect(() => {
    if (!requirements) return;

    setKycRequirements(requirements);

    const tempPopups = Object.entries(requirements).reduce((result, [path, value]) => {
      if (path.includes('dob')) return { ...result, [path]: false };

      return result;
    }, {});

    setIsShowPopups(tempPopups);
  }, [requirements]);

  const renderInput = (path, label) => {
    return (
      <MyInput
        key={path}
        value={kycRequirements[path]}
        onChange={(value) =>
          setKycRequirements((prevKycRequirements) => {
            const newKycRequirements = { ...prevKycRequirements, [path]: value };
            return newKycRequirements;
          })
        }
        placeholder={label}
        label={label}
        large
      />
    );
  };

  const renderCheckbox = (path, label) => {
    return (
      <div className="checkbox-input">
        <input
          type="checkbox"
          checked={kycRequirements[path]}
          onChange={(value) =>
            setKycRequirements((prevKycRequirements) => ({
              ...prevKycRequirements,
              [path]: value.target.checked,
            }))
          }
        />
        {label}
      </div>
    );
  };

  const renderCalendar = (path, label) => {
    return (
      <div className="popover-element kyc">
        <span className="title head4">
          {`${label}: `}
          <a
            className="title bold"
            onClick={() => setIsShowPopups((prevState) => ({ ...prevState, [path]: true }))}
          >
            {kycRequirements[path]?.toLocaleDateString() || `Select ${label}`}
          </a>
        </span>
        <MyCalendar
          isShow={isShowPopups[path]}
          setIsShow={(value) => setIsShowPopups((prevState) => ({ ...prevState, [path]: value }))}
          onChange={(value) =>
            setKycRequirements((prevKycRequirements) => ({
              ...prevKycRequirements,
              [path]: value,
            }))
          }
          value={kycRequirements[path]}
        />
      </div>
    );
  };

  const saveKycInfo = () => {
    setIsLoading(true);
    let doctor = JSON.parse(localStorage.getItem('gatsbyUser'));

    console.log(kycRequirements['company.address.city']);

    let kyc_data = {
      business_profile: {
        url: kycRequirements['business_profile.url'] || '',
      },
      company: {
        address: {
          city: kycRequirements['company.address.city'] || '',
          line1: kycRequirements['company.address.line1'] || '',
          postal_code: kycRequirements['company.address.postal_code'] || '',
          state: kycRequirements['company.address.state'] || '',
        },
        name: kycRequirements['company.name'],
        phone: kycRequirements['company.phone'],
        tax_id: kycRequirements['company.tax_id'],
      },
      owners: {
        email: kycRequirements['owners.email'],
        first_name: kycRequirements['owners.first_name'],
        last_name: kycRequirements['owners.last_name'],
        dob: kycRequirements['owners.dob'],
      },
      representative: {
        address: {
          city: kycRequirements['representative.address.city'],
          line1: kycRequirements['representative.address.line1'],
          postal_code: kycRequirements['representative.address.postal_code'],
          state: kycRequirements['representative.address.state'],
        },
        dob: kycRequirements['representative.dob'],
        email: kycRequirements['representative.email'],
        first_name: kycRequirements['representative.first_name'],
        last_name: kycRequirements['representative.last_name'],
        phone: kycRequirements['representative.phone'],
        ssn_last_4: kycRequirements['representative.ssn_last_4'],
      },
    };

    axios
      .post(
        `${process.env.GATSBY_API_URL}/api/v1/doctors/update_kyc_requirements.json`,
        { kyc_data: kyc_data, doctor: doctor.id },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        },
      )
      .then((response) => {
        console.log(response);
        localStorage.setItem(
          'gatsbyUser',
          JSON.stringify({ ...response.data, type: 'doctor', token: doctor?.token }),
        );
        setShow(false);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error('Error');
        setIsLoading(false);
      });
  };

  return (
    <ModalOverlay
      type="requirements"
      heading="Edit KYC Requirements"
      onConfirmLabel="Save"
      onCancelLabel="Cancel"
      onCancel={() => setShow(false)}
      onConfirm={handleSave}
      isLoading={isLoading}
      {...props}
    >
      <div className="inputs-wrapper">
        {kycRequirements && (
          <div>
            <span className="title head2 bold">Company</span>
            <div className="inputs-container">
              {Object.entries(companyLabels).map(([path, label]) => {
                if (!(path in kycRequirements)) return null;

                return renderInput(path, label);
              })}
            </div>
          </div>
        )}

        {kycRequirements && (
          <div>
            <span className="title head2 bold">Owners</span>
            <div className="inputs-container">
              {Object.entries(ownersLabels).map(([path, label]) => {
                if (!(path in kycRequirements)) return null;

                if (path.includes('dob')) return renderCalendar(path, label);

                return renderInput(path, label);
              })}
            </div>
          </div>
        )}

        {kycRequirements && (
          <div>
            <span className="title head2 bold">Representative</span>
            <div className="inputs-container">
              {Object.entries(representativeLabels).map(([path, label]) => {
                if (!(path in kycRequirements)) return null;

                if (path.includes('executive')) return renderCheckbox(path, label);

                if (path.includes('dob')) return renderCalendar(path, label);

                return renderInput(path, label);
              })}
            </div>
          </div>
        )}
      </div>
    </ModalOverlay>
  );
};

export default EditKycRequirementsModal;
