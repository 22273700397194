import React from 'react';
import SearchInput from '../../../../../collections/ui/input/searchInput/SearchInput';
import MySelect from '../../../../../collections/ui/select/MySelect';
import './PatientsFilter.scss';

const PatientsFilter = ({ filter, setFilter }) => {
  return (
    <div className="patients-filter">
      <SearchInput
        value={filter.query}
        onChange={(e) => setFilter({ ...filter, query: e.target.value })}
        placeholder="Search"
        large
      />
      <div className="select-container">
        <span className="title">Sort by:</span>
        <MySelect
          selectedOption={filter.sort}
          setSelected={(selectedSort) => setFilter({ ...filter, sort: selectedSort })}
          defaultValue="Select"
          options={[
            { value: 'first_name-L', name: 'First Name (A-Z)' },
            { value: 'first_name-H', name: 'First Name (Z-A)' },
            { value: 'last_name-L', name: 'Last Name (A-Z)' },
            { value: 'last_name-H', name: 'Last Name (Z-A)' },
          ]}
          small
        />
      </div>
    </div>
  );
};

export default PatientsFilter;
