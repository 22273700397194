import React, { useEffect, useState } from 'react';
import './LensesSelectionFilter.scss';
import MySelect from '../../../../../collections/ui/select/MySelect';

const LensesSelectionFilter = ({
  selectedOptions,
  setSelectedOptions,
  selectedEye,
  collectionItem,
  isEmpty,
  ...otherProps
}) => {
  const allProperties = {
    base_curve: 'Base Curve',
    diameter: 'Diameter',
    power: 'Power',
    centerThickness: 'Center Thickness',
    cylinder: 'Cylinder',
    axis: 'Axis',
    add_power: 'Add Power',
    multifocalType: 'Multifocal Type',
    color: 'Color',
    quantity: 'Quantity',
  };
  const spreadKeys = (array, defaultValue) =>
    array.reduce((result, item) => ({ ...result, [item]: defaultValue }), {});

  const [selectedProperty, setSelectedProperty] = useState(
    spreadKeys(Object.keys(allProperties), ''),
  );
  const [variantId, setVariantId] = useState('');

  const lensesTypes = collectionItem.variants.edges.map((variant) => variant.node.lensesType);
  const variants = collectionItem.variants.edges;

  const keyEye = selectedEye.toLowerCase().split(' ')[0];
  const realSelectedEye = keyEye === 'one' ? 'left' : keyEye;

  const [filterSelectors, setFilterSelectors] = useState();
  const [countMount, setCountMount] = useState(0);

  const [properties, setProperties] = useState(spreadKeys(Object.keys(allProperties), []));

  const [selectedPropertyVariants, setSelectedPropertyVariants] = useState(
    spreadKeys(Object.keys(allProperties), []),
  );
  const [selectedAllOptions, setSelectedAllOptions] = useState([]);

  // function for setting up selectors with unique values from variants of frame
  const uniqueVariants = (array, key, type) => {
    if (type === 'power') {
      const negative = [];
      const positive = [];

      array?.map((el) =>
        el.node[key].map((item) => {
          if (item > 0) {
            !positive.find(({ value }) => item === value) &&
              positive.push({ value: item, name: `+${item}` });
          } else {
            !negative.find(({ value }) => item === value) &&
              negative.push({ value: item, name: item });
          }
        }),
      );

      return [
        negative.sort((a, b) => b.value - a.value),
        positive.sort((a, b) => a.value - b.value),
      ];
    }

    if (type === 'coma') {
      const comaVariants = [];

      array?.map((item) => {
        item.node[key]?.split(', ')?.map((elem) => {
          !comaVariants.find(({ value }) => elem === value) &&
            comaVariants.push({ value: elem.trim(), name: elem.trim() });
        });
      });

      if (key === 'color') {
        return comaVariants.sort((a, b) => {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;

          return 0;
        });
      }

      if (key === 'cylinder' || key === 'add_power') {
        return comaVariants.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
      }

      if (key === 'axis') {
        return comaVariants.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
      }

      return comaVariants;
    }

    const resultArray = array?.reduce((result, item) => {
      if (!result.find((elem) => elem.value === item.node[key])) {
        return [...result, { value: item.node[key], name: item.node[key] }];
      }

      return result;
    }, []);

    if (key === 'baseCurve' || key === 'diameter') {
      return resultArray.sort((a, b) => a.value - b.value);
    }

    return resultArray;
  };

  useEffect(() => {
    if (!filterSelectors) return;

    if (countMount === 0) {
      if (Object.keys(selectedOptions).length > 0) {
        const toSelectedProperty = Object.entries(selectedOptions[realSelectedEye]).reduce(
          (result, [key, value]) => ({ ...result, [key]: value || '' }),
          {},
        );

        setSelectedProperty((prevState) => ({ ...prevState, ...toSelectedProperty }));
      }
    }

    if (countMount < 2) {
      setCountMount(countMount + 1);
    }
  }, [selectedOptions, filterSelectors]);

  useEffect(() => {
    const axisAndCylinderTypes = ['Toric', 'Toric Multifocal'];
    const addPowerTypes = ['Multifocal', 'Toric Multifocal'];
    const colorTypes = ['Sphere Color'];
    const sphereTypes = ['Sphere'];
    const foundAxisAndCylinder = lensesTypes.some((r) => axisAndCylinderTypes.indexOf(r) >= 0);
    const foundAddPower = lensesTypes.some((r) => addPowerTypes.indexOf(r) >= 0);
    const foundColor = lensesTypes.some((r) => colorTypes.indexOf(r) >= 0);
    const foundSphere = lensesTypes.some((r) => sphereTypes.indexOf(r) >= 0);
    const foundCenterThickness = variants.some(({ node }) => node.centerThickness !== null);
    const foundMultifocal = variants.some(({ node }) => node.multifocalType !== null);

    // filtering selectors by type of lens and special params
    setFilterSelectors((prevState) => ({
      ...prevState,
      axis: foundAxisAndCylinder,
      cylinder: foundAxisAndCylinder,
      add_power: foundAddPower,
      color: foundColor,
      centerThickness: foundCenterThickness,
      multifocalType: foundMultifocal,
      sphere: foundSphere,
    }));
  }, []);

  // setting up variant only if 1 variant exists with selected params
  useEffect(() => {
    selectedAllOptions?.length === 1
      ? setVariantId(selectedAllOptions[0].node.id)
      : setVariantId('');
  }, [selectedAllOptions]);

  useEffect(() => {
    if (!filterSelectors) return;

    if (countMount < 1) return;

    // setting up selector properties
    const tempVariants = {
      base_curve: uniqueVariants(variants, 'baseCurve'),
      diameter: uniqueVariants(selectedPropertyVariants.base_curve, 'diameter'),
      power: uniqueVariants(selectedPropertyVariants.diameter, 'powerRange', 'power'),
      add_power: uniqueVariants(selectedPropertyVariants.power, 'addPower', 'coma'),
      color: uniqueVariants(selectedPropertyVariants.power, 'color', 'coma'),
      axis: uniqueVariants(selectedPropertyVariants.cylinder, 'axis', 'coma'),
      cylinder: uniqueVariants(
        filterSelectors.centerThickness
          ? selectedPropertyVariants.centerThickness
          : selectedPropertyVariants.power,
        'cylinder',
        'coma',
      ),
      centerThickness: uniqueVariants(selectedPropertyVariants.power, 'centerThickness'),
      multifocalType: uniqueVariants(selectedPropertyVariants.add_power, 'multifocalType'),
      quantity: [...new Array(1)].map((el, index) => ({
        value: `${index + 1}`,
        name: `${index + 1}`,
      })),
    };

    const isExistingProperty = (arr, key) =>
      arr.find(({ value }) => value === selectedProperty[key]);

    // cancel selection if higher option changes and lower not in current list of variants
    const copiedProperty = Object.entries(tempVariants).reduce((result, [key, array]) => {
      if (array.length && selectedProperty[key].length) {
        if (key === 'power') {
          if (!array.map((values) => isExistingProperty(values, key)).find((item) => item)) {
            return { ...result, [key]: '' };
          }
        } else if (!isExistingProperty(array, key)) {
          return { ...result, [key]: '' };
        }
      }

      return result;
    }, {});

    // setting up selected property if there is only one in selector
    const toSelectedProperty = Object.entries(tempVariants).reduce((result, [key, value]) => {
      if (key === 'power') {
        return { ...result };
      }

      return !selectedProperty[key] && value.length === 1 && value[0].name
        ? { ...result, [key]: value[0].name }
        : { ...result };
    }, {});

    countMount > 1 &&
      Object.keys(copiedProperty).length &&
      setSelectedProperty((prevState) => ({
        ...prevState,
        ...copiedProperty,
      }));

    Object.keys(toSelectedProperty).length &&
      setSelectedProperty((prevState) => ({
        ...prevState,
        ...toSelectedProperty,
      }));

    setProperties((prevState) => ({ ...prevState, ...tempVariants }));
  }, [selectedPropertyVariants, filterSelectors, countMount]);

  useEffect(() => {
    if (!selectedProperty.base_curve) return;

    if (!filterSelectors) return;

    if (countMount < 1) return;

    // filtering variants by selected properties
    const onBaseCurveVariants = variants.filter(
      ({ node }) => selectedProperty.base_curve === node.baseCurve,
    );

    const onDiameterVariants = variants.filter(
      ({ node }) =>
        selectedProperty.base_curve === node.baseCurve &&
        selectedProperty.diameter === node.diameter,
    );

    const onPowerVariants = variants.filter(
      ({ node }) =>
        selectedProperty.base_curve === node.baseCurve &&
        selectedProperty.diameter === node.diameter &&
        node.powerRange?.some((r) => selectedProperty.power === r),
    );

    const onCenterThicknessVariants = variants.filter(
      ({ node }) =>
        selectedProperty.base_curve === node.baseCurve &&
        selectedProperty.diameter === node.diameter &&
        node.powerRange?.some((r) => selectedProperty.power === r) &&
        selectedProperty.centerThickness === node.centerThickness,
    );

    const onColorVariants = variants.filter(
      ({ node }) =>
        selectedProperty.base_curve === node.baseCurve &&
        selectedProperty.diameter === node.diameter &&
        node.powerRange?.some((r) => selectedProperty.power === r) &&
        node.color?.split(', ').some((c) => selectedProperty.color === c),
    );

    const onAddPowerVariants = variants.filter(
      ({ node }) =>
        selectedProperty.base_curve === node.baseCurve &&
        selectedProperty.diameter === node.diameter &&
        node.powerRange?.some((r) => selectedProperty.power === r) &&
        node.addPower?.split(', ').some((ap) => selectedProperty.add_power === ap),
    );

    const onMultifocalTypeVariants = variants.filter(
      ({ node }) =>
        selectedProperty.base_curve === node.baseCurve &&
        selectedProperty.diameter === node.diameter &&
        node.powerRange?.some((r) => selectedProperty.power === r) &&
        node.addPower?.split(', ').some((ap) => selectedProperty.add_power === ap) &&
        selectedProperty.multifocalType === node.multifocalType,
    );

    const onCenterCylinderVariants = variants.filter(
      ({ node }) =>
        selectedProperty.base_curve === node.baseCurve &&
        selectedProperty.diameter === node.diameter &&
        node.powerRange?.some((r) => selectedProperty.power === r) &&
        selectedProperty.centerThickness === node.centerThickness &&
        node.cylinder?.split(', ')?.some((r) => selectedProperty.cylinder === r),
    );

    const onCylinderVariants = variants.filter(
      ({ node }) =>
        selectedProperty.base_curve === node.baseCurve &&
        selectedProperty.diameter === node.diameter &&
        node.powerRange?.some((r) => selectedProperty.power === r) &&
        node.cylinder?.split(', ')?.some((r) => selectedProperty.cylinder === r),
    );

    // setting up filtered variants
    setSelectedPropertyVariants({
      base_curve: onBaseCurveVariants,
      diameter: onDiameterVariants,
      power: onPowerVariants,
      centerThickness: onCenterThicknessVariants,
      multifocalType: onMultifocalTypeVariants,
      color: onColorVariants,
      add_power: onAddPowerVariants,
      cylinder:
        filterSelectors.cylinder && filterSelectors.centerThickness
          ? onCenterCylinderVariants
          : onCylinderVariants,
    });

    // setting up main filter for all product
    if (filterSelectors.cylinder) {
      if (filterSelectors.centerThickness) {
        setSelectedAllOptions(onCenterCylinderVariants);
      } else {
        setSelectedAllOptions(onCylinderVariants);
      }
    }
    if (filterSelectors.sphere) {
      if (filterSelectors.centerThickness) {
        setSelectedAllOptions(onCenterThicknessVariants);
      } else {
        setSelectedAllOptions(onPowerVariants);
      }
    }
    if (filterSelectors.color) {
      setSelectedAllOptions(onColorVariants);
    }
    if (filterSelectors.add_power) {
      if (filterSelectors.multifocalType) {
        setSelectedAllOptions(onMultifocalTypeVariants);
      } else {
        setSelectedAllOptions(onAddPowerVariants);
      }
    }
  }, [selectedProperty, countMount]);

  useEffect(() => {
    if (!filterSelectors) return;

    if (countMount < 1) return;

    // setting up selected options directly in order
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [realSelectedEye]: {
        base_curve: selectedProperty.base_curve,
        diameter: selectedProperty.diameter,
        power: selectedProperty.power,
        centerThickness: filterSelectors.centerThickness ? selectedProperty.centerThickness : null,
        cylinder: filterSelectors.cylinder ? selectedProperty.cylinder : null,
        axis: filterSelectors.axis ? selectedProperty.axis : null,
        add_power: filterSelectors.add_power ? selectedProperty.add_power : null,
        multifocalType: filterSelectors.multifocalType ? selectedProperty.multifocalType : null,
        color: filterSelectors.color ? selectedProperty.color : null,
        quantity: selectedProperty.quantity,
        variant_id: variantId,
      },
    }));
  }, [selectedProperty, variantId, filterSelectors, countMount]);

  return (
    <div className="selected-eye-container">
      <div className="selected-eye-params-container">
        <div className="selected-eye">{selectedEye}</div>
      </div>
      <div className="filter-container">
        {filterSelectors &&
          Object.entries(properties).map(([key, value]) => {
            if (key in filterSelectors && !filterSelectors[key]) return;
            if (key === 'quantity') return;

            //doesn't display 'Accent Style' for '1-Day Acuvue Define 30pk' and power '-0.50'
            // and displays only color 'Honey' for 'FreshLook Colorblends 6pk' and power '-6.25'
            const options =
              key === 'color'
                ? selectedProperty.power === '-6.25' &&
                  collectionItem.title === 'FreshLook Colorblends 6pk'
                  ? [{ value: 'Honey', name: 'Honey' }]
                  : collectionItem.title === '1-Day Acuvue Define 30pk' &&
                    selectedProperty.power === '-0.50'
                  ? properties[key].filter((el) => el.value !== 'Accent Style')
                  : properties[key]
                : properties[key];

            return (
              <MySelect
                key={key}
                selectedOption={selectedProperty[key]}
                options={options}
                defaultValue={allProperties[key]}
                isEmpty={
                  isEmpty &&
                  (selectedOptions[realSelectedEye]
                    ? selectedOptions[realSelectedEye][key].length === 0
                    : !selectedOptions[realSelectedEye])
                }
                disabled={
                  key === 'power'
                    ? !properties[key].find((arr) => arr.length !== 0)
                    : !properties[key].length || properties[key].length === 1
                }
                setSelected={(value) =>
                  setSelectedProperty((prevState) => ({ ...prevState, [key]: value }))
                }
                {...otherProps}
              />
            );
          })}
      </div>
    </div>
  );
};

export default LensesSelectionFilter;
