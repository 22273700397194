import React, { useRef, useEffect } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

import PrimaryButton from '../button/PrimaryButton';
import './TipOverlay.scss';

const TipOverlay = ({ isShow, setShow, handleClick, children }) => {
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isShow && ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isShow]);

  return (
    <CSSTransition in={isShow} timeout={300} unmountOnExit>
      <div className="tip-container rounded-container light-blue" ref={ref}>
        {children}
        <PrimaryButton onClick={handleClick} label="Confirm" small gray />
      </div>
    </CSSTransition>
  );
};

export default TipOverlay;
