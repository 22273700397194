import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';

import MySelect from '../../../../../collections/ui/select/MySelect';
import FormikTextInput from '../../../../../shared/input/formikTextInput/FormikTextInput';
import RoundButton from '../../../../../shared/button/roundButton/RoundButton';
import PrimaryButton from '../../../../../shared/button/PrimaryButton';
import OrderContext from '../../../../../../context/OrderContext';
import { getUser, handleRegister } from '../../../../../../services/auth';

import './NewPatient.scss';
import { states } from './mockData';

const NewPatient = ({ location }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    zip: '',
  };

  const [selectedState, setSelectedState] = useState('');
  const [emptyField, setEmptyField] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const search = location?.href?.split('/');
  const { patient, setPatient, setCustomerId, setShippingId, setStripeClientSecret } =
    useContext(OrderContext);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('*Required')
      .matches(/^(?!\s+$).*/, '*Cannot contain only blankspaces'),
    lastName: Yup.string()
      .required('*Required')
      .matches(/^(?!\s+$).*/, '*Cannot contain only blankspaces'),
    email: Yup.string().email('*Please enter valid email').required('*Required'),
    phone: Yup.string()
      .required('*Required')
      .matches(/^[0-9]+$/, '*Phone must be a number')
      .min(8, '*Phone must contain at least 8 digits'),
    street: Yup.string()
      .required('*Required')
      .matches(/^(?!\s+$).*/, '*Cannot contain only blankspaces'),
    city: Yup.string()
      .required('*Required')
      .matches(/^(?!\s+$).*/, '*Cannot contain only blankspaces'),
    zip: Yup.string()
      .matches(/^[0-9]+$/, '*ZIP must be a number')
      .min(5, '*ZIP must contain at least 5 digits')
      .required('*Required'),
  });

  const handleSubmit = async (values, props) => {
    const trimmedValues = Object.entries(values).reduce(
      (result, [key, value]) => ({ ...result, [key]: value.trim() }),
      {},
    );

    if (!selectedState) {
      setEmptyField(true);
      return;
    }

    setIsButtonLoading(true);

    const user = getUser();
    let password = (Math.random() + 1).toString(36).substring(2);
    handleRegister({
      first_name: trimmedValues.firstName,
      last_name: trimmedValues.lastName,
      phone: trimmedValues.phone,
      email: trimmedValues.email,
      password: password,
      typeOfUser: 'customer',
      doctor_id: user.id,
    })
      .then((responseRegister) => {
        setCustomerId(responseRegister.data.user_id);
        console.log({
          first_name: trimmedValues.firstName,
          last_name: trimmedValues.lastName,
          email: trimmedValues.email,
          password: password,
          typeOfUser: 'customer',
          doctor_id: user.id,
        });
        axios
          .post(
            `${process.env.GATSBY_API_URL}/api/v1/shipping_addresses`,
            {
              shipping_address: {
                first_name: trimmedValues.firstName,
                last_name: trimmedValues.lastName,

                address1: trimmedValues.street,
                address2: '',
                city: trimmedValues.city,
                province: selectedState,
                zip: trimmedValues.zip,
                customer_id: responseRegister.data.user_id,
              },
            },

            { headers: { Authorization: user.token } },
          )
          .then((responseShipping) => {
            setPatient({
              ...patient,
              new: true,
              id: responseRegister.data.user_id,
              first_name: trimmedValues.firstName,
              last_name: trimmedValues.lastName,
              email: trimmedValues.email,
              phone: trimmedValues.phone,
              address: {
                street: trimmedValues.street,
                city: trimmedValues.city,
                state: selectedState,
                zipCode: trimmedValues.zip,
              },
            });
            console.log(responseShipping);
            setStripeClientSecret(responseShipping.data.shipping_address.client_secret);
            setShippingId(responseShipping.data.shipping_address.id);
            navigate(`${search?.slice(0, search?.length - 1).join('/')}/billing`);
          })
          .catch((e) => {
            setIsButtonLoading(false);
            toast.error('Error while setting up address');
          });
      })
      .catch((e) => {
        setIsButtonLoading(false);
        setEmptyField(true);
        toast.error('Email is already taken');
      });
  };

  useEffect(() => {
    if (Object.entries(patient).length > 1) {
      if (!patient?.new) {
        navigate(`${search?.slice(0, search.length - 1)?.join('/')}/order`);
        return;
      }
    }
  }, [patient]);

  const backToPrevious = () => {
    navigate(`${search?.slice(0, search?.length - 1).join('/')}/order`);
  };

  return (
    <div className="new-patient container">
      <div className="order-navigation">
        <RoundButton onClick={backToPrevious} />
        <span className="title head1 extrabold">Patient Information</span>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <div className="order-block-container">
              <span className="title bold uppercase">Personal information</span>
              <div className="rounded-container light-blue">
                <div className="inputs-row">
                  <FormikTextInput
                    errors={props.errors}
                    touched={props.touched}
                    label="First Name"
                    name="firstName"
                    placeholder="Enter First Name"
                    required
                    autoCompleteOff
                    type="text"
                    large
                  />
                  <FormikTextInput
                    errors={props.errors}
                    touched={props.touched}
                    label="Last Name"
                    name="lastName"
                    placeholder="Enter Last Name"
                    required
                    autoCompleteOff
                    type="text"
                    large
                  />
                </div>
                <div className="inputs-row">
                  <FormikTextInput
                    errors={props.errors}
                    touched={props.touched}
                    label="Email"
                    name="email"
                    placeholder="Enter Email"
                    required
                    autoCompleteOff
                    type="text"
                    large
                  />
                  <FormikTextInput
                    errors={props.errors}
                    touched={props.touched}
                    label="Phone Number"
                    name="phone"
                    placeholder="Enter Phone Number"
                    required
                    autoCompleteOff
                    type="text"
                    large
                  />
                </div>
              </div>
            </div>

            <div className="order-block-container">
              <span className="title bold uppercase">Shipping information</span>
              <div className="rounded-container light-blue">
                <div className="inputs-row">
                  <FormikTextInput
                    errors={props.errors}
                    touched={props.touched}
                    label="Street Address"
                    name="street"
                    placeholder="Enter Street Address"
                    required
                    autoCompleteOff
                    type="text"
                    large
                  />
                  <FormikTextInput
                    errors={props.errors}
                    touched={props.touched}
                    label="City"
                    name="city"
                    placeholder="Enter City"
                    required
                    autoCompleteOff
                    type="text"
                    large
                  />
                </div>
                <div className="inputs-row">
                  <MySelect
                    selectedOption={selectedState}
                    options={states}
                    label="State"
                    defaultValue="Choose State"
                    isEmpty={emptyField && !selectedState.length}
                    setSelected={(value) => setSelectedState(value)}
                    large
                  />
                  <FormikTextInput
                    errors={props.errors}
                    touched={props.touched}
                    label="ZIP"
                    name="zip"
                    placeholder="Enter ZIP"
                    required
                    autoCompleteOff
                    type="text"
                    large
                  />
                </div>
              </div>
              <div className="flex-box column center button-container">
                <PrimaryButton label="Continue" purple isLoading={isButtonLoading} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewPatient;
