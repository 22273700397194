import React from 'react';

import TipOverlay from '../TipOverlay';

const PowerTip = ({ isShow, setShow, setChecked }) => {
  const handleClick = () => {
    setChecked(true);
    setShow(false);
  };

  return (
    <TipOverlay isShow={isShow} setShow={setShow} handleClick={handleClick}>
      <span className="title head4 bold">Make sure you meant to choose + power, not -</span>
    </TipOverlay>
  );
};

export default PowerTip;
