import React, { useState, useEffect } from 'react';

import OrdersListItem from '../patients/components/ordersListItem/OrdersListItem';
import OrdersFilter from './ordersFilter/OrdersFilter';
import UpcomingOrder from '../patients/components/upcomingOrder/UpcomingOrder';
import Pagination from '../../../shared/pagination/Pagination';
import { useSortedDate, useWindowWidth } from '../../../../services/hooks';
import { calculatePagination, getUpcomingOrder } from '../../../../services/utils';
import { getOrderHistory, getSubscriptions } from '../../../../services/requests';
import Loader from '../../../loader/Loader';
import './Orders.scss';

const Orders = () => {
  const [ordersPerPage, setOrdersPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrdersPage, setCurrentOrdersPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [metaData, setMetaData] = useState();
  const [upcomingOrders, setUpcomingOrders] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [sort, setSort] = useState('');

  const paginate = (num) => setCurrentPage(currentPage + num);

  // const sortedOrders = useSortedDate(orders, sort);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    setIsLoading(true);
    setCurrentOrdersPage(1);
    setCurrentPage(1);

    getOrderHistory('doctor', 1, sort).then((response) => {
      setOrders(response.data.orders);
      setMetaData(response.data.meta);
      setIsLoading(false);
    });

    getSubscriptions('doctor')
      .then((response) => {
        setUpcomingOrders(JSON.parse(response.data.subscriptions));
      })
      .catch((e) => console.log(e));
  }, [sort]);

  useEffect(() => {
    if (windowWidth < 992) {
      setIsMobile(true);
      return setOrdersPerPage(3);
    }

    setIsMobile(false);
    return setOrdersPerPage(4);
  }, [windowWidth]);

  useEffect(() => {
    if (
      orders.length &&
      ordersPerPage * currentPage + 10 > orders.length &&
      currentOrdersPage < metaData?.total_pages
    ) {
      getOrderHistory('doctor', currentOrdersPage + 1, sort).then((response) => {
        setCurrentOrdersPage(currentOrdersPage + 1);
        setOrders((prevOrders) => [...prevOrders, ...response.data.orders]);
      });
    }
    setCurrentOrders(calculatePagination(orders, ordersPerPage, currentPage));
  }, [orders, ordersPerPage, currentPage, sort]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="container orders">
      <OrdersFilter sort={sort} setSort={setSort} isMobile={isMobile} />
      {!!upcomingOrders?.length && (
        <div className="upcoming-orders-list flex-box column">
          <span className="title bold uppercase">Upcoming orders:</span>

          {upcomingOrders.map((subscription) => (
            <OrdersListItem
              key={subscription.id}
              product={subscription.product}
              subscription={subscription}
              doctor={subscription.doctor}
              customerInfo={subscription.customer}
              upcoming
              isDoctor
            />
          ))}
        </div>
      )}
      <div className="orders-list">
        <div className="flex-box space-between orders-heading">
          <span className="title bold uppercase">Past orders</span>
          <span className="title head5 extrabold">{metaData?.total_count} shipments</span>
        </div>
        <div className="orders-list-container flex-box column">
          {currentOrders.map((order) => (
            <OrdersListItem
              key={order.id}
              order={order}
              doctor={order.doctor}
              upcoming={false}
              isDoctor
            />
          ))}
        </div>
        <Pagination
          collectionsPerPage={ordersPerPage}
          totalCollections={metaData?.total_count}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>
    </div>
  );
};

export default Orders;
