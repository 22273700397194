import React, { useContext } from 'react';

import OrderContext from '../../../../context/OrderContext';
import NewPatientButton from '../../../shared/button/newPatientButton/NewPatientButton';
import PatientsContainer from '../patients/components/patientsContainer/PatientsContainer';
import './ExistingPatient.scss';

const ExistingPatient = ({ location }) => {
  const collectionName = location?.href?.split('/')?.[6];

  const { setPatient, setBilling, setEyes, setItem } = useContext(OrderContext);

  const handleClick = () => {
    setPatient({});
    setBilling({});
    setEyes({});
    setItem({});
  };

  return (
    <div className="existing-patients container">
      <div className="flex-box space-between">
        <span className="title head1 extrabold">Select Patient</span>
        <NewPatientButton
          label="Order for New Patient"
          redirectTo={`/doctor/account/collections/${collectionName}/order`}
          onClick={handleClick}
        />
      </div>
      <PatientsContainer location={location} />
    </div>
  );
};

export default ExistingPatient;
