import React from 'react';

import CustomTableTitle from '../customTableTitle/CustomTableTitle';
import './KycTable.scss';

const KycTable = ({ title, editable, onClick, ...props }) => {
  return (
    <div className="custom-table-container extra-large">
      <CustomTableTitle
        title={title}
        editable={editable}
        redirectTo={props?.redirectTo}
        onClick={onClick}
      />
      <div className="product-table-body">
        {editable ? (
          <a onClick={onClick} className="title head3 extrabold add-information">
            Add information
          </a>
        ) : (
          <span className="title head3 extrabold purple">All information provided</span>
        )}
      </div>
    </div>
  );
};

export default KycTable;
