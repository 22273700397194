import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

import PrimaryButton from '../../../../../shared/button/PrimaryButton';
import RoundButton from '../../../../../shared/button/roundButton/RoundButton';
import OrderContext from '../../../../../../context/OrderContext';
import PatientNameContainer from '../../components/patientNameContainer/PatientNameContainer';
import CardInput from '../../../../../collections/ui/input/cardInput/CardInput';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import './PatientBilling.scss';

const PatientBilling = ({ location }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const search = location?.href?.split('/');
  const {
    billing,
    patient,
    stripeToken,
    stripeClientSecret,
    setStripeToken,
    setBilling,
    setIsComplete,
  } = useContext(OrderContext);

  const backToPrevious = () => {
    navigate(`${search.slice(0, -1).join('/')}/new-patient`);
  };

  const continueToNext = () => {
    if (!stripe || !elements) {
      return;
    }

    setIsButtonLoading(true);
    const cardElement = elements.getElement(CardElement);

    stripe
      .confirmCardSetup(stripeClientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${patient?.first_name} ${patient?.last_name}`,
          },
        },
      })
      .then((response) => {
        if (response?.setupIntent?.status === 'succeeded') {
          setIsComplete(true);
          setStripeToken(response.setupIntent.client_secret);
          navigate(`${search.slice(0, -1).join('/')}/summary`);
        } else {
          toast.error('Error while creating payment');
          setIsButtonLoading(false);
        }
      })
      .catch((e) => {
        setIsButtonLoading(false);
        toast.error('Stripe error');
      });
  };

  return (
    <div className="patient-billing container">
      {Object.entries(patient).length > 1 && (
        <PatientNameContainer name={`${patient?.first_name} ${patient?.last_name}`} />
      )}
      <div className="order-navigation">
        <RoundButton onClick={backToPrevious} />
        <span className="title head1 extrabold">Billing Information</span>
      </div>

      <div className="order-block-container">
        <span className="title bold uppercase">Credit or debit card information</span>
        <div className="rounded-container light-blue">
          <CardInput>
            <CardElement />
          </CardInput>
        </div>
        <div className="continue-button-container">
          <PrimaryButton
            label="Continue"
            onClick={continueToNext}
            isLoading={isButtonLoading}
            purple
          />
        </div>
      </div>
    </div>
  );
};

export default PatientBilling;
