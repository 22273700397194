import React, { useState, useEffect, memo } from 'react';

import './PatientsContainer.scss';
import { usePatients } from '../../hooks/usePatients';
import { useDebounce } from '../../../../../../services/hooks';
import { getCustomers } from '../../../../../../services/requests';
import PatientsFilter from '../patientsFilter/PatientsFilter';
import PatientsList from '../patientsList/PatientsList';
import Loader from '../../../../../loader/Loader';

const PatientsContainer = memo(({ location }) => {
  const [patients, setPatients] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    sort: '',
    query: '',
  });

  const debouncedFilter = useDebounce(filter, 500);

  useEffect(() => {
    getCustomers().then(({ data }) => {
      setPatients(data);
      setIsLoading(false);
    });
  }, [location]);

  const sortedAndSearchedPatients = usePatients(
    patients,
    debouncedFilter?.sort,
    debouncedFilter?.query,
  );

  if (isLoading || !sortedAndSearchedPatients) return <Loader />;

  return (
    <div className="patients container">
      <PatientsFilter filter={filter} setFilter={setFilter} />
      <PatientsList patientsList={sortedAndSearchedPatients} location={location} />
    </div>
  );
});

export default PatientsContainer;
