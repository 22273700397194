import React from 'react';
import './CurrentBalance.scss';
import dayjs from 'dayjs';

const CurrentBalance = ({ balance }) => {
  return (
    <div className="current-balance-wrap">
      <span className="title bold uppercase">Current balance</span>
      <div className="custom-table-container extra-large">
        <div className="custom-table-title">
          <span className="title bold">
            Next payout: {dayjs().endOf('month').format('MM/DD/YYYY')}
          </span>
        </div>
        <div className="product-table-body">
          <span className="title head2 extrabold">${Math.round(balance * 100) / 100}</span>
        </div>
      </div>
    </div>
  );
};

export default CurrentBalance;
