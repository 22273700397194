import { Link } from 'gatsby';
import React from 'react';
import './NewPatientButton.scss';
import NewPatient from '../../assets/newPatient.svg';

const NewPatientButton = ({ label, redirectTo, onClick }) => {
  return (
    <Link to={redirectTo} className="new-patient-button" onClick={onClick}>
      <NewPatient />
      <span className="title head7 extrabold">{label}</span>
    </Link>
  );
};

export default NewPatientButton;
