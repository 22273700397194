import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import OrderContext from '../../../../../../context/OrderContext';
import CustomTable from '../../../../../shared/table/CustomTable';
import PrimaryButton from '../../../../../shared/button/PrimaryButton';
import { getClientOrderPrescription } from '../../../../../../services/utils';
import './OrderConfirmation.scss';

const OrderConfirmation = () => {
  const { patient, eyes, item, totalPrice, setOrder } = useContext(OrderContext);
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    if (!eyes || !totalPrice) return;

    console.log(Object.keys(eyes));

    setOrderDetails({
      quantity: `${getClientOrderPrescription(eyes, 'quantity')}`,
      frequency: 'Every Month',
      price: `$${totalPrice}`,
      material: item?.materialOfContent,
      vendor: item?.vendor,
    });
  }, [eyes, totalPrice]);

  const handleClick = () => {
    setOrder({});
    navigate('/doctor/account');
  };

  const orderDetailsNames = {
    quantity: 'Quantity',
    frequency: 'Frequency',
    price: 'Total Price',
    material: 'Material',
    vendor: 'Manufacturer',
  };

  return (
    <div className="order-confirmation flex-box column center container">
      <div className="order-box flex-box column center">
        <div className="thanksgiving">
          <span className="title head3 extrabold">
            Thank You! {patient?.first_name} {patient?.last_name}'s
            <br />
            Subscription Confirmed
          </span>
        </div>
        <div className="order-block-container flex-box column">
          <span className="title">Order Details</span>
          <CustomTable title={item?.title} item={orderDetails} names={orderDetailsNames} half />
        </div>
        <PrimaryButton label="Back to Home" onClick={handleClick} purple />
      </div>
    </div>
  );
};

export default OrderConfirmation;
