import React from 'react';
import { Link } from 'gatsby';

import './PatientsListItem.scss';
import ShipmentButton from '../../../../../shared/button/shipmentButton/ShipmentButton';

const PatientsListItem = ({ patient, onClick, redirectTo }) => {
  const { first_name, last_name, email, shipmentFrequency } = patient;

  return (
    <Link onClick={onClick} to={redirectTo} className="rounded-container light-blue patient">
      <div className="patient-credentials">
        <span className="title head4 extrabold">
          {first_name} {last_name}
        </span>
        <span className="title">{email}</span>
      </div>
      {shipmentFrequency && (
        <ShipmentButton
          label={shipmentFrequency}
          active
          inactive={shipmentFrequency === 'Inactive'}
        />
      )}
    </Link>
  );
};

export default PatientsListItem;
