import React, { useRef, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CSSTransition from 'react-transition-group/CSSTransition';

import './MyCalendar.scss';

const MyCalendar = ({ value, onChange, isShow, setIsShow, ...props }) => {
  const ref = useRef();

  const handleChange = (e) => {
    onChange(e);
    setIsShow(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isShow && ref.current && !ref.current.contains(e.target)) {
        setIsShow(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isShow]);

  return (
    <CSSTransition in={isShow} timeout={300} unmountOnExit>
      <div className="my-calendar-container" ref={ref}>
        <Calendar className="my-calendar" onChange={handleChange} value={value} {...props} />
      </div>
    </CSSTransition>
  );
};

export default MyCalendar;
