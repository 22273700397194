import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

import ModalOverlay from '../ModalOverlay';
import MyInput from '../../../collections/ui/input/MyInput';
import './EditBankAccountModal.scss';
import axios from 'axios';

const EditBankAccountModal = ({ setShow, bankAccount, doctor_id, ...props }) => {
  const stripe = useStripe();

  const [accountHolderName, setAccountHolderName] = useState(bankAccount?.account_holder_name);
  const [accountNumber, setAccountNumber] = useState(bankAccount?.account_number);
  const [routingNumber, setRoutingNumber] = useState(bankAccount?.routing_number);
  const [emptyField, setEmptyField] = useState(false);

  const createBankToken = () => {
    let doctor = JSON.parse(localStorage.getItem('gatsbyUser'));

    stripe
      .createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: routingNumber,
        account_number: accountNumber,
        account_holder_name: accountHolderName,
        account_holder_type: 'individual',
      })
      .then(function (result) {
        axios
          .post(
            `${process.env.GATSBY_API_URL}/api/v1/doctors/connect_bank_account.json`,
            { token: result.token.id, doctor: doctor_id },
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
              },
            },
          )
          .then((response) => {
            console.log(response);
            localStorage.setItem(
              'gatsbyUser',
              JSON.stringify({ ...response.data, type: 'doctor', token: doctor?.token }),
            );
          })
          .catch((error) => {
            toast.error('Error');
          });

        // send this to backend

        // Handle result.error or result.token
      });
  };

  return (
    <ModalOverlay
      type="bank-account"
      heading="Edit Bank Information"
      onConfirmLabel="Save"
      onConfirm={() => createBankToken()}
      onCancelLabel="Cancel"
      onCancel={() => setShow(false)}
      large
      // onConfirm={handleSave}
      {...props}
    >
      <div className="flex-box column">
        <MyInput
          value={accountHolderName}
          label="Account Holder Name"
          onChange={(value) => setAccountHolderName(value)}
          placeholder="Enter Account Holder Name"
          isEmpty={emptyField && !accountHolderName.length}
          wide
        />
        <MyInput
          value={accountNumber}
          label="Account Number"
          onChange={(value) => setAccountNumber(value)}
          placeholder="Enter Account Number"
          isEmpty={emptyField && !accountNumber.length}
          wide
        />
        <MyInput
          value={routingNumber}
          label="Account Routing Number"
          onChange={(value) => setRoutingNumber(value)}
          placeholder="Enter Account Routing Number"
          isEmpty={emptyField && !routingNumber.length}
          wide
        />
      </div>
    </ModalOverlay>
  );
};

export default EditBankAccountModal;
