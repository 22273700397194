import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import PayoutsList from './components/payoutsList/PayoutsList';
import PayoutsFilter from './components/payoutsFilter/PayoutsFilter';
import CurrentBalance from './components/currentBalance/CurrentBalance';
import Loader from '../../../loader/Loader';
import { getPayouts } from '../../../../services/requests';
import { useSortedDate, useWindowWidth } from '../../../../services/hooks';
import './Payouts.scss';

const Payouts = () => {
  const [pastPayouts, setPastPayouts] = useState([]);
  const [newPayout, setNewPayout] = useState(0.0);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [sort, setSort] = useState();

  const sortedPastPayouts = useSortedDate(pastPayouts, sort);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    getPayouts().then(({ data }) => {
      console.log(data);
      const startOfMonth = dayjs().startOf('month');
      const filterByExpiration = data.filter((items) => {
        return dayjs(items.created_at) > startOfMonth;
      });
      let payout_total = filterByExpiration
        .map((item) => item.amount)
        .reduce((prev, curr) => prev + curr, 0);
      setPastPayouts(data);
      setNewPayout(payout_total);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (windowWidth < 576) return setIsMobile(true);

    setIsMobile(false);
  }, [windowWidth]);

  return (
    <div className="payouts-container container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CurrentBalance balance={newPayout} />
          <PayoutsFilter sort={sort} setSort={setSort} isMobile={isMobile} />
          <PayoutsList payouts={sortedPastPayouts} />
        </>
      )}
    </div>
  );
};

export default Payouts;
