import React from 'react';
import './ShipmentButton.scss';
import classNames from 'classnames';

const ShipmentButton = ({ label, active, inactive, onClick, isEmpty }) => {
  const shipmentButtonClass = classNames({
    'shipment-button': true,
    active: active,
    inactive: inactive,
    empty: isEmpty,
  });

  return (
    <button className={shipmentButtonClass} onClick={onClick}>
      <span className="title head7 extrabold">{label}</span>
    </button>
  );
};

export default ShipmentButton;
