import React from 'react';

import SortDateButton from '../../../../../shared/button/sortDateButton/SortDateButton';
import './PayoutsFilter.scss';

const PayoutsFilter = ({ sort, setSort, isMobile }) => {
  const handleSort = (sortOption) => {
    sortOption === sort ? setSort('') : setSort(sortOption);
  };

  return (
    <div className="payouts-filter-container">
      {!isMobile && <span className="title">Filtered by:</span>}
      <SortDateButton onClick={() => handleSort('created_at-H')} active={sort === 'created_at-H'} />
      <SortDateButton
        onClick={() => handleSort('created_at-L')}
        active={sort === 'created_at-L'}
        end
      />
    </div>
  );
};

export default PayoutsFilter;
