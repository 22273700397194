import React, { useContext, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

import OrderContext from '../../../../../../context/OrderContext';
import PatientNameContainer from '../../components/patientNameContainer/PatientNameContainer';
import CustomTable from '../../../../../shared/table/CustomTable';
import HeadingTable from '../../../../../shared/table/headingTable/HeadingTable';
import DoubleTable from '../../../../../shared/table/doubleTable/DoubleTable';
import ShippingTable from '../../../../../shared/table/shippingTable/ShippingTable';
import PrimaryButton from '../../../../../shared/button/PrimaryButton';
import EditShipmentModal from '../../../../../shared/modals/editShipmentModal/EditShipmentModal';
import EditAddressModal from '../../../../../shared/modals/editAddressModal/EditAddressModal';
import EditPersonalInfoModal from '../../../../../shared/modals/editPersonalInfoModal/EditPersonalInfoModal';
import EditPrescriptionModal from '../../../../../shared/modals/editPrescriptionModal/EditPrescriptionModal';
import { patientNames, shippingNames, eyeNames } from './tableNames';
import { getUser } from '../../../../../../services/auth';
import { handleShowModal } from '../../../../../../services/utils';
import { updateCustomerAddress, createOrderPOST } from '../../../../../../services/requests';
import { useWindowWidth } from '../../../../../../services/hooks';
import './Summary.scss';
import dayjs from 'dayjs';

const Summary = ({ location }) => {
  const search = location?.pathname.split('/');
  const {
    eyes,
    patient,
    item,
    stripeToken,
    shippingId,
    expirationDate,
    setEyes,
    setPatient,
    setIsEditing,
    setTotalPrice,
    setExpirationDate,
  } = useContext(OrderContext);
  const [orderCost, setOrderCost] = useState(0);
  const [shippingFee, setShippingFee] = useState(30);
  const [showModal, setShowModal] = useState({
    billing: false,
    shipment: false,
    address: false,
    patient: false,
    prescription: false,
  });
  const [orderConfirmed, setOrderConfirmed] = useState(true);
  const [isDevice, setIsDevice] = useState({
    mobile: false,
    tablet: false,
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const user = getUser();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const totalCount = Object.entries(eyes).reduce(
      (acc, [key, value]) => (acc += Number(value?.quantity)),
      0,
    );

    setOrderCost((Number(item?.price) * totalCount).toFixed(2));
  }, [item, eyes]);

  useEffect(() => {
    if (windowWidth < 576) return setIsDevice((prevState) => ({ ...prevState, mobile: true }));
    if (windowWidth < 768)
      return setIsDevice((prevState) => ({ ...prevState, mobile: false, tablet: true }));

    return setIsDevice({ mobile: false, tablet: false });
  }, [windowWidth]);

  const submitOrder = async () => {
    setIsButtonLoading(true);
    const order = {
      order: {
        doctor_id: user?.id,
        customer_id: patient?.id,
        stripe_token: stripeToken,
        order_items: Object.entries(eyes).map(([key, value]) => ({
          product_id: item?.id,
          eye_side: key,
          ...value,
        })),
        shipping_address_id: shippingId,
        prescription_expiration_date: expirationDate,
      },
    };

    createOrderPOST(order)
      .then((response) => {
        setTotalPrice(orderCost);
        navigate(`${search.slice(0, -1).join('/')}/confirmation`);
      })
      .catch((e) => {
        setIsButtonLoading(false);
        toast.error('Order failed');
      });
  };

  const handleChangeName = (first_name, last_name, phone) => {
    setPatient({ ...patient, first_name, last_name, phone });
  };

  const handleChangeAddress = (address1, city, province, zip) => {
    const shippingAddress = {
      id: shippingId,
      address1,
      address2: '',
      city,
      province,
      zip,
      customer_id: patient?.id,
    };

    return updateCustomerAddress(shippingAddress, 'doctor')
      .then((response) => {
        const { street_address, city, province, zip } = response.data;
        setPatient({
          ...patient,
          address: {
            street: street_address,
            city,
            state: province,
            zipCode: zip,
          },
        });
      })
      .catch((e) => toast.error('Error while updating address'));
  };

  return (
    <div className="summary container">
      <PatientNameContainer name={`${patient?.first_name} ${patient?.last_name}`} />
      <div className="flex-box space-between summary-info">
        <div className="order-info-container">
          <HeadingTable
            title="Product"
            product={item?.title}
            large
            editable
            redirectTo={`${search.slice(0, -2).join('/')}`}
            onClick={() => setIsEditing(true)}
          />
          {Object.entries(eyes).length > 1 ? (
            <DoubleTable
              title="Prescription"
              subtitles={['Left Eye (OS)', 'Right Eye (OD)']}
              names={[eyeNames, eyeNames]}
              items={Object.entries(eyes).map(([key, value]) => value)}
              large
              editable
              onClick={() => handleShowModal({ prescription: true }, setShowModal)}
              hideEmpty
            />
          ) : (
            <CustomTable
              title="Prescription"
              item={eyes?.left}
              names={eyeNames}
              large
              half
              editable
              onClick={() => handleShowModal({ prescription: true }, setShowModal)}
              hideEmpty
            />
          )}
          {expirationDate && (
            <CustomTable
              title="Billing Information"
              item={{ expiration_date: dayjs(expirationDate).format('MMMM DD, YYYY') }}
              names={{ expiration_date: 'Expiration Date' }}
              large
              half
            />
          )}
          <CustomTable
            title="Product"
            hideEmpty
            item={{ materialOfContent: item?.materialOfContent, vendor: item?.vendor }}
            names={{ materialOfContent: 'Material', vendor: 'Manufacturer' }}
            large
            half
          />
          <div className="flex-box space-between patient-info">
            <CustomTable
              title="Patient Information"
              item={patient}
              names={patientNames}
              editable
              onClick={() => handleShowModal({ patient: true }, setShowModal)}
              large={isDevice.tablet || isDevice.mobile}
              half={isDevice.tablet || isDevice.mobile}
            />
            <CustomTable
              title="Shipping Information"
              item={patient?.address}
              names={shippingNames}
              editable
              onClick={() => handleShowModal({ address: true }, setShowModal)}
              large={isDevice.tablet || isDevice.mobile}
              half={isDevice.tablet || isDevice.mobile}
            />
          </div>
        </div>
        <div className="billing-info-container">
          <ShippingTable
            title="Total Cost"
            shipping={{ frequency: patient?.shipmentFrequency, cost: orderCost }}
            large={isDevice.tablet}
            // comment functionality shipment frequency
            // editable
            // onClick={() => handleShowModal({ shipment: true }, setShowModal)}
          />
          <div className="certifying">
            <input
              onChange={() => setOrderConfirmed(!orderConfirmed)}
              type="checkbox"
              checked={!orderConfirmed}
            />
            <label>
              I certify that this patient has a valid and current prescription, and the information
              provided here is consistent with the patient's prescription. I agree to update or
              cancel this patient's subscription if his or her prescription changes or expires.
            </label>
          </div>
          <PrimaryButton
            label="Confirm Subscription"
            big={!isDevice.mobile}
            large={isDevice.mobile}
            purple
            inactive={orderConfirmed}
            isLoading={isButtonLoading}
            onClick={submitOrder}
          />
        </div>
      </div>
      {
        // comment functionality shipment frequency
        /* <EditShipmentModal
        isShow={showModal.shipment}
        setShow={(value) => handleShowModal({ shipment: value }, setShowModal)}
      /> */
      }
      <EditAddressModal
        address={patient?.address}
        setAddress={handleChangeAddress}
        isShow={showModal.address}
        setShow={(value) => handleShowModal({ address: value }, setShowModal)}
      />
      <EditPersonalInfoModal
        item={patient}
        setItem={handleChangeName}
        isShow={showModal.patient}
        setShow={(value) => handleShowModal({ patient: value }, setShowModal)}
      />
      <EditPrescriptionModal
        eyes={eyes}
        item={item}
        expirationDate={expirationDate}
        setEyes={setEyes}
        setExpirationDate={setExpirationDate}
        isShow={showModal.prescription}
        setShow={(value) => handleShowModal({ prescription: value }, setShowModal)}
      />
    </div>
  );
};

export default Summary;
