import React, { useState } from 'react';
import dayjs from 'dayjs';

import PrimaryButton from '../../button/PrimaryButton';
import MyCalendar from '../../calendar/MyCalendar';
import ModalOverlay from '../ModalOverlay';
import LensesSelectionFilter from '../../../account/doctorAccount/products/components/LensesSelectionFilter/LensesSelectionFilter';
import './EditPrescriptionModal.scss';

const EditPrescriptionModal = ({
  eyes,
  item,
  expirationDate,
  setEyes,
  setExpirationDate,
  isShow,
  setShow,
}) => {
  const [isOneEyeContacts, setIsOneEyeContacts] = useState(Object.keys(eyes).length === 1);
  const [selectedOptions, setSelectedOptions] = useState(eyes || {});
  const [expirationDateState, setExpirationDateState] = useState(expirationDate);
  const [isCalendarShown, setIsCalendarShown] = useState(false);
  const [isEmptyField, setIsEmptyField] = useState(false);

  const handleSave = () => {
    setEyes({ ...selectedOptions });
    setExpirationDate(expirationDateState);
    setShow(false);
  };

  const handleCancel = () => {
    setShow(false);
    setIsOneEyeContacts(Object.keys(eyes).length === 1);
    setSelectedOptions(eyes || {});
  };

  const handleConfirm = () => {
    if (!selectedOptions) {
      setIsEmptyField(true);
      return;
    }

    const selectedOptionsArray = Object.entries(selectedOptions).map((item) => item);

    const isEmpty = selectedOptionsArray
      .map(([eye, props]) =>
        Object.entries(props).filter(([key, value]) => value !== null && value.length === 0),
      )
      .find((field) => field?.length !== 0);

    isEmpty ? setIsEmptyField(true) : handleSave();
  };

  const onCheckboxClick = () => {
    setIsEmptyField(false);
    setSelectedOptions({});
    setIsOneEyeContacts(!isOneEyeContacts);
  };

  if (isOneEyeContacts === undefined) return <></>;

  return (
    <ModalOverlay
      type="prescription"
      heading="Edit Prescription Information"
      onConfirmLabel="Confirm"
      onCancelLabel="Cancel"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      large
      isShow={isShow}
    >
      <div className="flex-box column edit-prescription">
        <label className="flex-box center">
          <input type="checkbox" checked={isOneEyeContacts} onChange={onCheckboxClick}></input>
          <span className="title head5">Need contacts for one eye only?</span>
        </label>
        {isOneEyeContacts ? (
          <LensesSelectionFilter
            key="one"
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            selectedEye={'One Eye'}
            collectionItem={item}
            isEmpty={isEmptyField}
            little
          />
        ) : (
          <>
            <LensesSelectionFilter
              key="left"
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              selectedEye={'Left Eye (OS)'}
              collectionItem={item}
              isEmpty={isEmptyField}
              little
            />
            <LensesSelectionFilter
              key="right"
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              selectedEye={'Right Eye (OD)'}
              collectionItem={item}
              isEmpty={isEmptyField}
              little
            />
          </>
        )}
        <div className="mt-4 d-flex popover-element">
          {expirationDateState && (
            <span className="m-3">{dayjs(expirationDateState).format('MMMM DD, YYYY')}</span>
          )}
          <PrimaryButton
            label={expirationDateState ? 'Change Expiration Date' : 'Set Expiration Date'}
            purple
            onClick={() => setIsCalendarShown(true)}
          />
          <MyCalendar
            isShow={isCalendarShown}
            setIsShow={(value) => setIsCalendarShown(value)}
            onChange={(value) => setExpirationDateState(value)}
            value={expirationDateState}
            minDate={new Date()}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};

export default EditPrescriptionModal;
