export const patientNames = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  phone: 'Phone',
};

export const shippingNames = {
  street: 'Address',
  city: 'City',
  state: 'State',
  zipCode: 'ZIP',
};

export const billingNames = {
  name: 'Name',
  number: 'Card Number',
  expiration: 'Expiration',
  cvv: 'CVV',
};

export const eyeNames = {
  base_curve: 'Base Curve',
  diameter: 'Diameter',
  power: 'Power',
  add_power: 'Add Power',
  cylinder: 'Cylinder',
  axis: 'Axis',
  color: 'Color',
  multifocalType: 'Multifocal Type',
  quantity: 'Quantity',
};

export const expirationDate = {
  expiration_date: 'Expiration Date',
};
