export const personalInfoNames = {
  first_name: 'First Name',
  last_name: 'Last Name',
  phone: 'Phone',
};

export const accountInfoNames = {
  email: 'Email',
  password: 'Password',
};

export const creditCardNames = {
  cardNumber: 'Card Number Last 4',
  brand: 'Brand',
  expYear: 'Exp Year',
  expMonth: 'Exp Month',
};

export const bankAccountNames = {
  account_holder_name: 'Account Holder Name',
  routing_number: 'Routing Number',
  bank_name: 'Bank Name',
};
