import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';

import PatientsListItem from '../patientsListItem/PatientsListItem';
import Pagination from '../../../../../shared/pagination/Pagination';
import OrderContext from '../../../../../../context/OrderContext';
import { calculatePagination } from '../../../../../../services/utils';
import { useWindowWidth } from '../../../../../../services/hooks';
import './PatientsList.scss';

const PatientsList = ({ patientsList, location }) => {
  const [currentPatients, setCurrentPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage, setPatientsPerPage] = useState(8);
  const { setOrder, setPatient } = useContext(OrderContext);
  const search = location?.pathname.split('/');
  const collectionName = search && search[search.length - 2];
  const windowWidth = useWindowWidth();

  const paginate = (num) => setCurrentPage(currentPage + num);

  const handleClick = (patient) => {
    setPatient(patient);

    if (location) {
      setOrder({ patient });
    }
  };

  useEffect(() => {
    if (windowWidth < 576) return setPatientsPerPage(5);

    return setPatientsPerPage(8);
  }, [windowWidth]);

  useEffect(() => {
    setCurrentPatients(calculatePagination(patientsList, patientsPerPage, currentPage));
  }, [patientsList, patientsPerPage, currentPage]);

  if (!patientsList) return <></>;

  return (
    <>
      <div className="title head5 extrabold patient">{patientsList.length} Patients</div>
      {currentPatients.length ? (
        <>
          <div className="patients-list-container">
            {currentPatients?.map((patient, index) => (
              <PatientsListItem
                key={index}
                patient={patient}
                onClick={() => handleClick(patient)}
                redirectTo={
                  location
                    ? `/doctor/account/collections/${collectionName}/order`
                    : `/doctor/account/patients/${patient.id}/orders`
                }
              />
            ))}
          </div>
          <Pagination
            collectionsPerPage={patientsPerPage}
            totalCollections={patientsList.length}
            currentPage={currentPage}
            paginate={paginate}
          />
        </>
      ) : (
        <span className="title head4">No results matching this search</span>
      )}
    </>
  );
};

export default PatientsList;
