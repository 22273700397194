import { useMemo } from 'react';

export const useSortedPatients = (patients, sort) => {
  const sortedPatients = useMemo(() => {
    if (sort) {
      const sortValue = sort.split('-')[0];
      const sortOption = sort.split('-')[1];

      return sortOption === 'H'
        ? [...patients].sort((a, b) => b[sortValue].localeCompare(a[sortValue]))
        : [...patients].sort((a, b) => a[sortValue].localeCompare(b[sortValue]));
    }
    return patients;
  }, [sort, patients]);

  return sortedPatients;
};

export const usePatients = (patients, sort, query) => {
  const sortedPatients = useSortedPatients(patients ? patients : [], sort);
  const sortedAndSearchedPatients = useMemo(
    () =>
      sortedPatients.filter(
        (patient) =>
          `${patient.first_name} ${patient.last_name}`
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          patient.email.toLowerCase().includes(query.toLowerCase()),
      ),
    [query, sortedPatients],
  );

  return patients ? sortedAndSearchedPatients : null;
};
