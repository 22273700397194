import React, { useState } from 'react';

import PayoutsListItem from '../payoutsListItem/PayoutsListItem';
import Pagination from '../../../../../shared/pagination/Pagination';
import { calculatePagination } from '../../../../../../services/utils';
import './PayoutsList.scss';

const PayoutsList = ({ payouts }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [payoutsPerPage, setPayoutsPerPage] = useState(5);
  const paginate = (num) => setCurrentPage(currentPage + num);

  const currentPayouts = calculatePagination(payouts, payoutsPerPage, currentPage);
  console.log(currentPayouts);

  return (
    <div className="payouts-list-wrapper">
      <span className="title bold uppercase">Past payouts:</span>
      <div className="payouts-list-container">
        {currentPayouts.map(({ id, ...otherProps }) => (
          <PayoutsListItem key={id} id={id} {...otherProps} />
        ))}
      </div>
      <Pagination
        collectionsPerPage={payoutsPerPage}
        totalCollections={payouts.length}
        currentPage={currentPage}
        paginate={paginate}
      />
    </div>
  );
};

export default PayoutsList;
