import React from 'react';
import './PatientNameContainer.scss';

const PatientNameContainer = ({ name }) => {
  return (
    <div className="patient-name-container">
      <span className="title head3">
        Patient: <span className="title head3 extrabold">{name}</span>
      </span>
    </div>
  );
};

export default PatientNameContainer;
