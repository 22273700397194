import React from 'react';

import './SortDateButton.scss';
import SortUp from '../../assets/sortUp.svg';
import SortDown from '../../assets/sortDown.svg';
import classNames from 'classnames';

const SortDateButton = ({ end, onClick, active }) => {
  const label = end ? 'End Date' : 'Start Date';
  const sortDateButtonClass = classNames({
    'sort-date-button': true,
    active,
  });

  return (
    <button className={sortDateButtonClass} onClick={onClick}>
      {end ? <SortDown /> : <SortUp />}
      <span className="title head7 extrabold">{label}</span>
    </button>
  );
};

export default SortDateButton;
