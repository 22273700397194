import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';

import OrderContext from '../../../../context/OrderContext';
import PrimaryButton from '../../../shared/button/PrimaryButton';
import RoundButton from '../../../shared/button/roundButton/RoundButton';
import LensesSelectionFilter from './components/LensesSelectionFilter/LensesSelectionFilter';
import ShipmentButton from '../../../shared/button/shipmentButton/ShipmentButton';
import PowerTip from '../../../shared/tips/powerTip/PowerTip';
import BaseCurveTip from '../../../shared/tips/baseCurveTip/BaseCurveTip';
import PatientNameContainer from './components/patientNameContainer/PatientNameContainer';
import EditAddressModal from '../../../shared/modals/editAddressModal/EditAddressModal';
import { handleShowModal, getLatestAddress } from '../../../../services/utils';
import './PatientOrder.scss';
import { getDoctorCustomerAddress, createCustomerAddress } from '../../../../services/requests';
import MyCalendar from '../../../shared/calendar/MyCalendar';

const PatientOrder = ({ location, data }) => {
  const [collectionItem, setCollectionItem] = useState();
  const [isOneEyeContacts, setIsOneEyeContacts] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isEmptyField, setIsEmptyField] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isTipChecked, setIsTipChecked] = useState({
    baseCurve: false,
    power: false,
  });
  const [activeShipment, setActiveShipment] = useState('');
  const [showModal, setShowModal] = useState({
    address: false,
    baseCurve: false,
    powerTip: false,
  });
  const [expirationDateState, setExpirationDateState] = useState(null);
  const [isCalendarShown, setIsCalendarShown] = useState(false);
  const [isEmptyExpirationDate, setIsEmptyExpirationDate] = useState(false);
  const [isPastExpirationDate, setIsPastExpirationDate] = useState(false);
  const {
    patient,
    eyes,
    setStripeClientSecret,
    setShippingId,
    isComplete,
    expirationDate,
    setPatient,
    setEyes,
    setItem,
    setIsComplete,
    setExpirationDate,
  } = useContext(OrderContext);
  const search = location?.href?.split('/');

  const shipmentPeriods = ['every month', 'three months', 'six months', 'nine months', 'one year'];

  const onExpirationDateChange = (value) => {
    setExpirationDateState(value);
    setIsEmptyExpirationDate(false);

    if (dayjs(value).diff(dayjs().format('YYYY-MM-DD')) < 0) return setIsPastExpirationDate(true);

    setIsPastExpirationDate(false);
  };

  const continueToNext = () => {
    setIsButtonLoading(true);
    setItem(collectionItem);
    setEyes(selectedOptions);
    setExpirationDate(expirationDateState);

    if (isComplete) {
      // comment functionality shipment frequency
      // setPatient({ ...patient, shipmentFrequency: activeShipment });
      setPatient(patient);
      navigate(`${search.slice(0, search.length - 1).join('/')}/summary`);
      return;
    }

    if (Object.entries(patient).length > 1 && !patient?.new) {
      getDoctorCustomerAddress(patient?.id)
        .then((response) => {
          const { id, address1, city, province, zip } = getLatestAddress(response.data);

          setShippingId(id);
          setPatient({
            ...patient,
            address: {
              street: address1,
              city,
              state: province,
              zipCode: zip,
            },
            // comment functionality shipment frequency
            // shipmentFrequency: activeShipment,
          });
          setIsComplete(true);
          navigate(`${search.slice(0, search.length - 1).join('/')}/summary`);
          setIsButtonLoading(false);
        })
        .catch((e) => {
          setIsButtonLoading(false);
          handleShowModal({ address: true }, setShowModal);
        });
    } else {
      // comment functionality shipment frequency
      // setPatient({ ...patient, shipmentFrequency: activeShipment });
      setPatient(patient);
      navigate(`${search.slice(0, search.length - 1).join('/')}/new-patient`);
      setIsButtonLoading(false);
    }
  };

  const handleContinue = () => {
    if (!selectedOptions) {
      setIsEmptyField(true);
      return;
    }

    // comment functionality shipment frequency
    // if (!activeShipment) {
    //   setIsEmptyField(true);
    //   return;
    // }

    const selectedOptionsArray = Object.entries(selectedOptions).map((item) => item);

    const selectedBaseCurveArray = selectedOptionsArray.map(([eye, props]) =>
      Object.entries(props).find(([key, value]) => key === 'base_curve'),
    );

    const selectedPowerArray = selectedOptionsArray.map(([eye, props]) =>
      Object.entries(props).find(([key, value]) => key === 'power'),
    );

    let isDifferentBaseCurves;

    if (
      !selectedBaseCurveArray.find(([key, value]) => !value.length) &&
      selectedBaseCurveArray?.length > 1
    ) {
      isDifferentBaseCurves = selectedOptions.left.base_curve !== selectedOptions.right.base_curve;
      !isTipChecked.baseCurve &&
        isDifferentBaseCurves &&
        handleShowModal({ baseCurve: true }, setShowModal);
    }

    let isPositivePower;

    if (selectedPowerArray.find(([key, value]) => value > 0)) {
      !isTipChecked.power && handleShowModal({ powerTip: true }, setShowModal);
      isPositivePower = true;
    } else {
      isPositivePower = false;
    }

    const isEmpty = selectedOptionsArray
      .map(([eye, props]) =>
        Object.entries(props).filter(([key, value]) => value !== null && value.length === 0),
      )
      .find((field) => field?.length !== 0);

    if (isEmpty) {
      setIsEmptyField(true);
    } else if (!expirationDateState) {
      setIsEmptyExpirationDate(true);
      return;
    } else if (isEmptyExpirationDate || isPastExpirationDate) {
      return;
    } else if (isDifferentBaseCurves && !isTipChecked.baseCurve) {
      return;
    } else if (isPositivePower && !isTipChecked.power) {
      return;
    } else {
      continueToNext();
    }
  };

  const handleCreateAddress = (address1, city, province, zip) => {
    const shippingAddress = {
      first_name: patient?.first_name,
      last_name: patient?.last_name,
      address1,
      address2: '',
      city,
      province,
      zip,
      customer_id: patient?.id,
    };

    return createCustomerAddress(shippingAddress).then((response) => {
      setPatient({
        ...patient,
        address: {
          street: address1,
          city,
          state: province,
          zipCode: zip,
        },
      });
      setStripeClientSecret(response.data.shipping_address.client_secret);
      setShippingId(response.data.shipping_address.id);
      navigate(`${search.slice(0, search.length - 1).join('/')}/summary`);
    });
  };

  const onCheckboxClick = () => {
    setIsEmptyField(false);
    setSelectedOptions({});
    setIsOneEyeContacts(!isOneEyeContacts);
  };

  const onBack = () => {
    navigate(`${search.slice(0, -1).join('/')}/existing-patient`);
  };

  useEffect(() => {
    const eyesLength = Object.keys(eyes).length;

    if (eyesLength) {
      setIsOneEyeContacts(eyesLength === 1 ? true : false);
      setSelectedOptions(eyes);
    }

    if (expirationDate) {
      setExpirationDateState(expirationDate);
    }
  }, [patient, eyes, expirationDate]);

  useEffect(() => {
    const collectionName = search?.[6].split('%20').join(' ');
    const collectionItemTemp = data.contactsportalapi.productList.edges.find(
      (collection) => collection.node.title.toLowerCase().replace('+ ', '') === collectionName,
    )?.node;
    if (!collectionItemTemp?.variants) return;
    setCollectionItem(collectionItemTemp);
    setIsLoading(false);
  }, []);

  return (
    <>
      {!isLoading && (
        <div className="patient-order container">
          <div className="order-block-container">
            {Object.entries(patient).length > 1 && (
              <PatientNameContainer name={`${patient?.first_name} ${patient?.last_name}`} />
            )}
            <div className="order-navigation">
              {Object.entries(patient).length > 1 && !patient?.new && (
                <RoundButton onClick={onBack} />
              )}

              <span className="title head1">{collectionItem.title}</span>
            </div>
            <div className="prescription-info">
              <div className="prescription-info-title">
                <div className="title bold uppercase">Enter Prescription & Quantity</div>
                <label className="contacts-container">
                  <input
                    type="checkbox"
                    checked={isOneEyeContacts}
                    onChange={onCheckboxClick}
                  ></input>
                  <span className="title head5">Need contacts for one eye only?</span>
                </label>
              </div>
            </div>
            <div className="rounded-container light-blue">
              {isOneEyeContacts ? (
                <LensesSelectionFilter
                  key="one"
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  selectedEye={'One Eye'}
                  collectionItem={collectionItem}
                  isEmpty={isEmptyField}
                />
              ) : (
                <>
                  <LensesSelectionFilter
                    key="left"
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    selectedEye={'Left Eye (OS)'}
                    collectionItem={collectionItem}
                    isEmpty={isEmptyField}
                  />
                  <LensesSelectionFilter
                    key="right"
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    selectedEye={'Right Eye (OD)'}
                    collectionItem={collectionItem}
                    isEmpty={isEmptyField}
                  />
                </>
              )}

              <div className="m-4 p-2 d-flex popover-element">
                {expirationDateState && (
                  <span className="m-3">{dayjs(expirationDateState).format('MMMM DD, YYYY')}</span>
                )}
                <PrimaryButton
                  label={expirationDateState ? 'Change Expiration Date' : 'Set Expiration Date'}
                  purple
                  onClick={() => setIsCalendarShown(true)}
                />
                <MyCalendar
                  isShow={isCalendarShown}
                  setIsShow={(value) => setIsCalendarShown(value)}
                  onChange={onExpirationDateChange}
                  value={expirationDateState}
                  minDate={new Date()}
                />
              </div>
              {isEmptyExpirationDate && (
                <p className="p-2 expiration-date-error">Expiration Date Cannot Be Empty</p>
              )}
              {isPastExpirationDate && (
                <p className="p-2 expiration-date-error">Expiration Date Cannot Be In Past</p>
              )}
            </div>
          </div>

          {
            // comment functionality shipment frequency
            /* <div className="order-block-container">
            <div className="title bold uppercase">Select shipment frequency</div>
            <div className="rounded-container light-blue">
              <div className="shipment-buttons-container">
                {shipmentPeriods?.map((period, index) => (
                  <ShipmentButton
                    key={index}
                    label={period}
                    active={activeShipment && period === activeShipment.toLowerCase()}
                    onClick={() => setActiveShipment(period)}
                    isEmpty={isEmptyField && !activeShipment}
                  />
                ))}
              </div>
            </div>
          </div> */
          }

          <div className="continue-button-container">
            <div className="popover-element">
              <PrimaryButton
                onClick={handleContinue}
                isLoading={isButtonLoading}
                label="Continue"
                purple
              />
              <PowerTip
                isShow={showModal.powerTip}
                setShow={(value) => handleShowModal({ powerTip: value }, setShowModal)}
                setChecked={(value) =>
                  setIsTipChecked((prevState) => ({ ...prevState, power: value }))
                }
              />
              <BaseCurveTip
                isShow={showModal.baseCurve}
                setShow={(value) => handleShowModal({ baseCurve: value }, setShowModal)}
                setChecked={(value) =>
                  setIsTipChecked((prevState) => ({ ...prevState, baseCurve: value }))
                }
              />
            </div>
          </div>
          <EditAddressModal
            address={patient?.address}
            setAddress={handleCreateAddress}
            isShow={showModal.address}
            setShow={(value) => handleShowModal({ address: value }, setShowModal)}
          />
        </div>
      )}
    </>
  );
};

export default PatientOrder;
