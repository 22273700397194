import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout/Layout';
import PrivateRoute from '../components/PrivateRoute';
import Login from '../components/login/Login';
import Register from '../components/register/Register';
import PayoutInfo from '../components/register/payoutInfo/PayoutInfo';
import Account from '../components/account/Account';
import Collections from '../components/collections/Collections';
import Orders from '../components/account/doctorAccount/orders/Orders';
import Payouts from '../components/account/doctorAccount/payouts/Payouts';
import PatientOrder from '../components/account/doctorAccount/products/PatientOrder';
import ExistingPatient from '../components/account/doctorAccount/products/ExistingPatient';
import PatientOrders from '../components/account/doctorAccount/patients/pages/orders/PatientOrders';
import PatientSubscription from '../components/account/doctorAccount/patients/pages/subscription/PatientSubscription';
import PatientInfo from '../components/account/doctorAccount/patients/pages/info/PatientInfo';
import Patients from '../components/account/doctorAccount/patients/Patients';
import AccountInfo from '../components/account/doctorAccount/accountInfo/AccountInfo';
import ResetPassword from '../components/resetPassword/ResetPassword';
import NewPasswordForm from '../components/resetPassword/newPasswordForm/NewPasswordForm';
import RegisterConfirmation from '../components/register/registerConfirmation/RegisterConfirmation';
import NewPatient from '../components/account/doctorAccount/products/pages/newPatient/NewPatient';
import PatientBilling from '../components/account/doctorAccount/products/pages/patientBilling/PatientBilling';
import Summary from '../components/account/doctorAccount/products/pages/summary/Summary';
import OrderConfirmation from '../components/account/doctorAccount/products/pages/orderConfirmation/OrderConfirmation';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { graphql } from 'gatsby';
import { DoctorContextProvider } from '../context/DoctorContext';
import { PatientContextProvider } from '../context/PatientContext';
import { OrderContextProvider } from '../context/OrderContext';

const Doctor = ({ location, data }) => {
  const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PK_KEY}`);

  return (
    <OrderContextProvider>
      <DoctorContextProvider>
        <PatientContextProvider>
          <Layout location={location}>
            <Elements stripe={stripePromise}>
              <Router basepath="/doctor">
                <PrivateRoute path="/account" component={Account} />
                <PrivateRoute path="/account/collections" component={Collections} data={data} />
                <PrivateRoute
                  path="/account/collections/:collections_name/new-patient"
                  component={NewPatient}
                />
                <PrivateRoute
                  path="/account/collections/:collections_name/billing"
                  component={PatientBilling}
                />
                <PrivateRoute
                  path="/account/collections/:collections_name/order"
                  component={PatientOrder}
                  data={data}
                />
                <PrivateRoute
                  path="/account/collections/:collections_name/existing-patient"
                  component={ExistingPatient}
                />
                <PrivateRoute
                  path="/account/collections/:collections_name/summary"
                  component={Summary}
                />
                <PrivateRoute
                  path="/account/collections/:collections_name/confirmation"
                  component={OrderConfirmation}
                />
                <PrivateRoute path="/account/patients" component={Patients} />
                <PrivateRoute
                  path="/account/patients/:patient_id/orders"
                  component={PatientOrders}
                />
                <PrivateRoute
                  path="/account/patients/:patient_id/subscription"
                  component={PatientSubscription}
                />
                <PrivateRoute
                  path="/account/patients/:patient_id/account-info"
                  component={PatientInfo}
                />
                <PrivateRoute path="/account/orders" component={Orders} />
                <PrivateRoute path="/account/payouts" component={Payouts} />
                <PrivateRoute path="/account/info" component={AccountInfo} />
                <ResetPassword path="/login/forgot-password" typeOfUser="doctor" />
                <NewPasswordForm path="/login/forgot-password/reset-form" typeOfUser="doctor" />
                <Login path="/login" typeOfUser="doctor" />
                {/*
                comment register functionality
                <Register path="/register" typeOfUser="doctor" />
                <RegisterConfirmation path="/registration-confirmation" />
                */}
                <PayoutInfo path="/register/payment-information" typeOfUser="doctor" />
              </Router>
            </Elements>
          </Layout>
        </PatientContextProvider>
      </DoctorContextProvider>
    </OrderContextProvider>
  );
};

export default Doctor;

export const query = graphql`
  query {
    contactsportalapi {
      productList {
        edges {
          node {
            id
            title
            description
            brand
            imageUrl
            price
            shippingPrice
            lensType
            packageDetails
            materialOfContent
            water
            vendor
            variants {
              edges {
                node {
                  id
                  baseCurve
                  diameter
                  powerRange
                  addPower
                  cylinder
                  axis
                  centerThickness
                  visibilityTint
                  color
                  colorCollection
                  replacementPeriod
                  multifocalType
                  lensesType
                  vendorName
                }
              }
            }
          }
        }
      }
    }
  }
`;
