export const companyLabels = {
  'business_profile.mcc': 'Business Profile MCC',
  'business_profile.url': 'Business Profile URL',
  'company.address.city': 'Company Address City',
  'company.address.line1': 'Company Address Line 1',
  'company.address.postal_code': 'Company Address Postal Code',
  'company.address.state': 'Company Address State',
  'company.name': 'Company Name',
  'company.phone': 'Company Phone',
  'company.tax_id': 'Company Tax Id',
};

export const ownersLabels = {
  'owners.email': 'Email',
  'owners.first_name': 'First Name',
  'owners.last_name': 'Last Name',
  'owners.dob': 'Date of Birth',
};

export const representativeLabels = {
  'representative.email': 'Email',
  'representative.first_name': 'First Name',
  'representative.last_name': 'Last Name',
  'representative.phone': 'Phone',
  'representative.dob': 'Date of Birth',
  'representative.address.line1': 'Address Line',
  'representative.address.city': 'Address City',
  'representative.address.state': 'Address State',
  'representative.address.postal_code': 'Address ZIP Code',
  'representative.ssn_last_4': 'SSN Last 4',
};
