import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';

import './AccountInfo.scss';
import {
  personalInfoNames,
  accountInfoNames,
  creditCardNames,
  bankAccountNames,
} from './tableNames';
import CustomTable from '../../../shared/table/CustomTable';
import KycTable from '../../../shared/table/kycTable/KycTable';
import EditPersonalInfoModal from '../../../shared/modals/editPersonalInfoModal/EditPersonalInfoModal';
import EditEmailModal from '../../../shared/modals/editEmailModal/EditEmailModal';
import EditPasswordModal from '../../../shared/modals/editPasswordModal/EditPasswordModal';
import EditCreditCardModal from '../../../shared/modals/editCreditCardModal/EditCreditCardModal';
import EditBankAccountModal from '../../../shared/modals/editBankAccountModal/EditBankAccountModal';
import EditKycRequirementsModal from '../../../shared/modals/editKycRequirementsModal/EditKycRequirementsModal';
import { handleShowModal } from '../../../../services/utils';
import {
  updatePersonalInfoPUT,
  updatePasswordPOST,
  getDoctorPaymentInfo,
} from '../../../../services/requests';
import { setUser, getUser } from '../../../../services/auth';
import DoctorContext from '../../../../context/DoctorContext';
import PrimaryButton from '../../../shared/button/PrimaryButton';

const AccountInfo = () => {
  const [doctor, setDoctor] = useState();
  const [kycRequirements, setKycRequirements] = useState();
  const [creditCard, setCreditCard] = useState({
    cardNumber: '',
    expYear: '',
    expMonth: '',
    brand: '',
  });
  const [payoutAccountType, setPayoutAccountType] = useState('card');
  const [bankAccount, setBankAccount] = useState({
    account_holder_name: '',
    routing_number: '',
    bank_name: '',
  });
  const [showModal, setShowModal] = useState({
    personalInfo: false,
    // comment functionality edit email
    // email: false,
    password: false,
    creditCard: false,
    bankAccount: false,
    kycRequirements: false,
  });
  const [currentPassword, setCurrentPassword] = useState('Change Password');
  const { setContextDoctor } = useContext(DoctorContext);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const tempDoctor = getUser();

    const object = tempDoctor.kyc_requirements.reduce((acc, el) => ({ ...acc, [el]: '' }), {});
    setDoctor(tempDoctor);

    const kycRepresentative = tempDoctor.kyc_requirements?.find(
      (el) =>
        el === 'representative.dob.year' ||
        el === 'representative?.dob?.month' ||
        el === 'representative?.dob?.month',
    )
      ? {
          ...object,
          'representative.dob': null,
        }
      : {};
    const kycOwners = tempDoctor.kyc_requirements?.find(
      (el) =>
        el === 'owners.dob.year' || el === 'owners?.dob?.month' || el === 'owners?.dob?.month',
    )
      ? {
          'owners.dob': null,
        }
      : {};

    const kyc = { ...object, ...kycRepresentative, ...kycOwners };
    Object.keys(object).length !== 0 ? setKycRequirements(kyc) : setKycRequirements({});
    getDoctorPaymentInfo(tempDoctor.id)
      .then((response) => {
        console.log(response);
        let account = response?.data?.account;
        setPayoutAccountType(account?.object);
        if (account?.object === 'card') {
          setCreditCard({
            cardNumber: account.dynamic_last4,
            expMonth: account.exp_month,
            expYear: account.exp_year,
            brand: account.brand,
          });
        }
        if (account?.object === 'bank_account') {
          setBankAccount({
            account_holder_name: account.account_holder_name,
            bank_name: account.bank_name,
            routing_number: account.routing_number,
          });
        }
      })
      .catch(() => console.log('Error while getting bank information'));
    Object.keys(object).length !== 0 ||
    (Object.keys(object).length !== 1 && object.external_account)
      ? setKycRequirements(kyc)
      : setKycRequirements({});
  }, [showModal.kycRequirements]);

  const handleChangeName = (first_name, last_name, phone) => {
    updatePersonalInfoPUT('doctor', first_name, last_name, phone)
      .then((response) => {
        const doctorObj = { ...doctor, first_name, last_name, phone };
        setUser(doctorObj);
        setDoctor(doctorObj);
        setContextDoctor(doctorObj);
        toast.success('Your information successfully changed!');
      })
      .catch((e) => toast.error('Error while updating information'));
  };

  const handleChangePassword = (password, passwordConfirmation) => {
    updatePasswordPOST('doctor', password, passwordConfirmation)
      .then((response) => toast.success('Password successfully changed!'))
      .catch((e) => toast.error('Error while updating password'));
  };

  return (
    <div className="container account-info">
      <div className="tables-container-wrapper">
        <span className="title bold uppercase">Details</span>
        <div className="tables-container">
          <CustomTable
            title="Personal Information"
            item={doctor}
            names={personalInfoNames}
            editable
            onClick={() => handleShowModal({ personalInfo: true }, setShowModal)}
            extraLarge
            half
          />
          <CustomTable
            title="Account Information"
            item={{ ...doctor, password: currentPassword }}
            names={accountInfoNames}
            // comment functionality edit email
            // editable
            // onClick={() => handleShowModal({ email: true }, setShowModal)}
            extraClick={{ password: () => handleShowModal({ password: true }, setShowModal) }}
            extraLarge
            half
          />
        </div>
      </div>
      <div className="tables-container-wrapper">
        <span className="title bold uppercase">Billing</span>
        {!doctor?.payout_setup && (
          <div className="custom-table-container w-100">
            <h4 className="custom-table-title text-center">
              Connect A Payment Source to be able to sell products
            </h4>
            <div className="bank-info p-3">
              <PrimaryButton
                label="Bank Account"
                purple
                onClick={() => handleShowModal({ bankAccount: true }, setShowModal)}
              />
              <span className="bank-info-text m-2">or</span>
              <PrimaryButton
                label="Debit Card"
                purple
                onClick={() => handleShowModal({ creditCard: true }, setShowModal)}
              />
            </div>
          </div>
        )}
        <div className="tables-container">
          {doctor?.payout_setup && payoutAccountType === 'card' && (
            <CustomTable
              title="Credit Card"
              item={creditCard}
              names={creditCardNames}
              editable
              onClick={() => handleShowModal({ creditCard: true }, setShowModal)}
              extraLarge
              half
            />
          )}
          {doctor?.payout_setup && payoutAccountType === 'bank_account' && (
            <CustomTable
              title="Bank Account"
              item={bankAccount}
              names={bankAccountNames}
              editable
              onClick={() => handleShowModal({ bankAccount: true }, setShowModal)}
              extraLarge
              half
            />
          )}
        </div>
      </div>
      <div className="tables-container-wrapper">
        <span className="title bold uppercase">KYC Requirements</span>
        <KycTable
          title="Requirements"
          editable={kycRequirements && Object.entries(kycRequirements).length !== 0}
          onClick={() => handleShowModal({ kycRequirements: true }, setShowModal)}
        />
      </div>
      <EditPersonalInfoModal
        item={doctor}
        setItem={handleChangeName}
        isShow={showModal.personalInfo}
        setShow={(value) => handleShowModal({ personalInfo: value }, setShowModal)}
      />
      {
        // comment functionality edit email
        /* <EditEmailModal
        isShow={showModal.email}
        email={doctor?.email}
        setEmail={(value) => setDoctor((prevState) => ({ ...prevState, email: value }))}
        setShow={(value) => handleShowModal({ email: value }, setShowModal)}
      /> */
      }
      <EditPasswordModal
        setPassword={handleChangePassword}
        isShow={showModal.password}
        setShow={(value) => handleShowModal({ password: value }, setShowModal)}
      />
      <EditCreditCardModal
        isShow={showModal.creditCard}
        doctor_id={doctor?.id}
        setShow={(value) => handleShowModal({ creditCard: value }, setShowModal)}
      />
      <EditBankAccountModal
        bankAccount={bankAccount}
        isShow={showModal.bankAccount}
        doctor_id={doctor?.id}
        setShow={(value) => handleShowModal({ bankAccount: value }, setShowModal)}
      />
      <EditKycRequirementsModal
        requirements={kycRequirements}
        setRequirements={setKycRequirements}
        isShow={showModal.kycRequirements}
        setShow={(value) => handleShowModal({ kycRequirements: value }, setShowModal)}
      />
    </div>
    // <div className="account-info-container">
    //   <div className="bankInfoContainer">
    //     <h2>Bank Info</h2>
    //     <button className="btn btn-primary" onClick={() => createBankToken()}>
    //       Submit Bank Info
    //     </button>

    //     <h3 className="cardInfo">Debit Card Info</h3>
    //     <div className="cardHolder">
    //       <CardElement />
    //     </div>
    //     <button className="btn btn-primary" onClick={() => createDebitCardToken()}>
    //       Submit Card Info
    //     </button>
    //   </div>

    // </div>
  );
};

export default AccountInfo;
