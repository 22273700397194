import React from 'react';

import SortDateButton from '../../../../shared/button/sortDateButton/SortDateButton';
import ExportButton from '../../../../shared/button/exportButton/ExportButton';
import './OrdersFilter.scss';

const OrdersFilter = ({ sort, setSort, isMobile }) => {
  const handleSort = (sortOption) => {
    sortOption === sort ? setSort('') : setSort(sortOption);
  };

  return (
    <div className="orders-filter-container">
      <div className="sorting">
        {!isMobile && <span className="title">Filtered by:</span>}
        <SortDateButton onClick={() => handleSort('desc')} active={sort === 'desc'} />
        <SortDateButton onClick={() => handleSort('asc')} active={sort === 'asc'} end />
      </div>
      {/* <ExportButton /> */}
    </div>
  );
};

export default OrdersFilter;
